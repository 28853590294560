import 'moment/locale/ru';
import Workbook from "exceljs/index";


const RepairsReportPage = (repairsList: any, workbook: Workbook) => {
    // создаем листы в книге
    const worksheet = workbook.addWorksheet('Неисправные СКК', {
        views: [{showGridLines: true}]
    });

    // делаем шапку //
    worksheet.getCell("B2").value = "№";
    worksheet.getColumn(2).width = 8

    worksheet.getCell("C2").value = "гос.номер";
    worksheet.getColumn(3).width = 12

    worksheet.getCell("D2").value = "ПО";
    worksheet.getColumn(4).width = 15

    worksheet.getCell("E2").value = "В ремонте с";
    worksheet.getColumn(5).width = 12

    worksheet.getCell("F2").value = "В ремонте по";
    worksheet.getColumn(6).width = 12

    worksheet.getCell("G2").value = "Примечание";
    worksheet.getColumn(7).width = 25

    worksheet.getCell("H2").value = "Ответственный";
    worksheet.getColumn(8).width = 15

    // Задаем стиль для ячеек шапки
    const style = {
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        }
    };

    // перебераем шапку таблицы
    const row = worksheet.getRow(2);
    for (let i = 2; i <= 8; i++) {
        const cell = row.getCell(i);
        // @ts-ignore
        cell.border = style.border;
        cell.font = {bold: true}
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '66ccff'},
        };
    }

    // выравниваем по центру и по середине, перенос строк
    for (let col = 2; col <= 8; col++) {
        const column = worksheet.getColumn(col);
        column.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true
        };
    }

    // инсертим данные
    let rowNum = 0
    repairsList.map((e: any, index: number) => {
        const row = worksheet.getRow(index + 3)
        row.values = ['', index + 1, e['equipage'], e['org'], e['date_from'],
            e['date_to'], e['comment'], e['fio']];

        // Добавляем границы вокруг ячеек
        for (let i = 2; i <= 8; i++) {
            const cell = row.getCell(i)
            // @ts-ignore
            cell.border = style.border;
        }

    })
}

export default RepairsReportPage
