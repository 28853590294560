import 'moment/locale/ru';
import Workbook from "exceljs/index";


const CliffReportPage = (cliffList: any, workbook: Workbook) => {
    // создаем листы в книге
    const worksheet = workbook.addWorksheet('Обрывы', {
        views: [{showGridLines: true}]
    });

    // делаем шапку //
    worksheet.getCell("B2").value = "№";
    worksheet.getColumn(2).width = 8

    worksheet.getCell("C2").value = "Месторождение";
    worksheet.getColumn(3).width = 19

    worksheet.getCell("D2").value = "Куст";
    worksheet.getColumn(4).width = 10

    worksheet.getCell("E2").value = "Скважина";
    worksheet.getColumn(5).width = 10

    worksheet.getCell("F2").value = "Дата обрыва";
    worksheet.getColumn(6).width = 12

    worksheet.getCell("G2").value = "Дата извлечения";
    worksheet.getColumn(7).width = 12

    worksheet.getCell("H2").value = "Примечание";
    worksheet.getColumn(8).width = 25

    worksheet.getCell("I2").value = "Организация";
    worksheet.getColumn(9).width = 15

    // Задаем стиль для ячеек шапки
    const style = {
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        }
    };

    // перебераем шапку таблицы
    const row = worksheet.getRow(2);
    for (let i = 2; i <= 9; i++) {
        const cell = row.getCell(i);
        // @ts-ignore
        cell.border = style.border;
        cell.font = {bold: true}
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '66ccff'},
        };
    }

    // выравниваем по центру и по середине, перенос строк
    for (let col = 2; col <= 9; col++) {
        const column = worksheet.getColumn(col);
        column.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true
        };
    }

    // инсертим данные
    let rowNum = 0
    cliffList.map((e: any, index: number) => {
        //         'pk', 'cliff_date', 'extraction_date', 'comment', 'wells', 'depth', 'org_cliff', 'org_extract',
        // 'created_at', 'updated_at'
        const row = worksheet.getRow(index + 3)
        row.values = ['', index + 1, e['wells']['bush']['deposit'], e['wells']['bush']['name'], e['wells']['name'],
            e['cliff_date'], e['extraction_date'], e['comment'], e['org_cliff']['name']];

        // Добавляем границы вокруг ячеек
        for (let i = 2; i <= 9; i++) {
            const cell = row.getCell(i)
            // @ts-ignore
            cell.border = style.border;
        }


    })


}

export default CliffReportPage
