import 'moment/locale/ru'; // импортируем русскую локаль
import {API_REPORTS_REPORT1} from "../../../service/api.endpoint";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import GetTaskResult from "../../../component/result.dramatiq.cmp";
import MainPageReport from "./mainReport";


const ReportToExcel = async (date: string, setLoading: any, data?: any) => {

    if (data) {
        MainPageReport(data, setLoading, date);
    } else {
        // Генерируем UUID для таска драматик
        const requestId = uuidv4();

        const response = await axios.get(API_REPORTS_REPORT1, {params: {date: date, requestId: requestId}})

        if (response.data['status'] === 'OK') {
            let dramatiqResult = false

            // получение результата таск
            const fetchResult = async () => {
                let resultTask = await GetTaskResult(requestId);
                if (!resultTask.data) {
                    setTimeout(fetchResult, 2000);
                    return;
                } else {
                    MainPageReport(resultTask, setLoading, date)
                    dramatiqResult = true
                }
            }
            // циклим пока не придет выполненная таска от драматик
            if (!dramatiqResult) {
                await fetchResult();
            }
        }
    }
};

export default ReportToExcel;
