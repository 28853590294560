// @ts-ignore
import ExcelJs from "exceljs";
import moment from "moment/moment";
import 'moment/locale/ru'; // импортируем русскую локаль
import '../../../component/css/ico.css'

function formatCell(level: string, sheet: any, e: any, el: any, index: number, i: number) {
    let color;
    let val;

    if (level === 'Легкая') {
        color = '80BF20'
        val = '2';
    } else if (level === 'Средняя') {
        color = 'FFDF60'
        val = '2';
    } else if (level === 'Тяжелая') {
        color = '9F2000'
        val = '2';
    } else if (level === 'Непроход') {
        color = 'DF20DF'
        val = '3';
    }
    // заливка ячейки цветом
    sheet.getCell(index + 10, i + 17).fill =
        {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: color}
        };
    // устанавливаем значение в ячейку
    sheet.getCell(index + 10, i + 17).value = val;

    // комментарии
    if (e[el]?.comment) {
        // sheet.getCell(index + 10, 51).value = e[el].comment.label
        sheet.getCell(index + 10, i + 17).note = e[el].comment.label
        if (e[el].comment.label !== 'ЛГПП' && e[el].comment.label !== 'ППК') {
            sheet.getCell(index + 10, i + 17).fill =
                {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'C0C0C0'}
                };
            sheet.getCell(index + 10, i + 17).value = 4
        }
    }

    // well fill by НЕПРОХОД
    if (val === '3') {
        sheet.getCell(index + 10, 6).fill =
            {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'DF20DF'}
            };
    } else {
        sheet.getCell(index + 10, 6).fill = null
    }
}


const ExportToExcel = (dataGraph: any) => {
    // отметки о результатах прохода на графике
    function checkComment(sheet: any, e: any, index: number) {
        for (let i = 1; i <= 31; i++) {
            const el = `d${i}`
            if (e[el] !== null) {
                let bsiData
                let fullData
                try {
                    fullData = e[el]
                    bsiData = e[el].bsi[e[el].bsi.length - 1]
                } catch (error) {
                    bsiData = null
                }
                // форматируем ячейки, красим исправляем номера на соответствующие ЭРЕ
                if (bsiData && bsiData.descent_total) {
                    formatCell(bsiData.bsi_level?.name, sheet, e, el, index, i)
                } else if (!bsiData) {
                    formatCell(fullData.level?.label, sheet, e, el, index, i)
                } else if (e[el]?.comment && e[el]?.comment.label !== 'ЛГПП' && e[el]?.comment.label !== 'ППК') {
                    let color = 'DF20DF'
                    let val = '';
                    sheet.getCell(index + 10, i + 17).fill =
                        {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {argb: color}
                        };
                    sheet.getCell(index + 10, i + 17).value = val;
                    // комментарии
                    if (e[el].comment) {
                        sheet.getCell(index + 10, i + 17).note = e[el].comment.label
                    }
                }
            }

        }
    }

    const exportToExcel = (dataGraph: any) => {
        let allDataSheet = {}
        // перечисляем все возможные листы в книге
        const sheetList = {east: "ВОСТОК", north: "СЕВЕР", west: "ЗАПАД", south: "ЮГ", salym: "Салым"}
        if (dataGraph.data) {
            // разбиваем на локации и убираем не нужные данные
            for (let sheetKey in sheetList) {
                // @ts-ignore
                const tempData = dataGraph.data.filter((el: any) => el.location === sheetList[sheetKey])
                if (tempData.length > 0) {
                    allDataSheet = {
                        ...allDataSheet,
                        [sheetKey]: tempData.map((obj: any, index: any) => {
                            const {pk, workDate, location, ...rest} = obj;
                            return {'№': index + 1, ...rest};
                        })
                    };
                }
            }
        }
        let workbook = new ExcelJs.Workbook();
        let dataLocation: { sheet: any, data: any }[] = [];

        // создаем листы в книге
        for (let key in allDataSheet) {
            if (Object.prototype.hasOwnProperty.call(allDataSheet, key)) {
                const sheetKey = key as keyof typeof sheetList;
                dataLocation.push({
                    sheet: workbook.addWorksheet(sheetList[sheetKey], {
                        views: [{showGridLines: true}]
                    }),
                    // @ts-ignore
                    data: allDataSheet[sheetKey]
                })
            }
        }

        // шапка таблицы !!!!
        const columnArr = ['№', 'куст', 'ДНС', 'Месторождение', 'Исполнитель', 'Скважина',
            'Hсп', 'верх отлож', 'низ отлож', 'хар.отлож', 'Режим работы', 'Время работы, мин',
            'Время накоп, мин', 'Идентификатор мероприятия', 'Идентификатор исполнителя',
            'Идентификатор мероприятие/исполнитель', 'Дфр', '1', '2', '3', '4', '5', '6', '7',
            '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21',
            '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', 'Итого план', 'Итого факт',
            'Примечание', 'Новые', 'Обрывы', 'БСИ степень'];

        // Задаем стиль для ячеек шапки
        const style = {
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FF99CCFF'} // Цвет в формате argb
            },
            border: {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            }
        };

        // задает ширину столбцов
        function setColumnWidth(worksheet: any) {
            worksheet.getColumn(1).width = 5
            worksheet.getColumn(2).width = 8
            worksheet.getColumn(3).width = 9
            worksheet.getColumn(4).width = 18
            worksheet.getColumn(5).width = 16
            worksheet.getColumn(6).width = 14
            worksheet.getColumn(7).width = 6
            worksheet.getColumn(8).width = 7
            worksheet.getColumn(9).width = 7
            worksheet.getColumn(10).width = 9
            worksheet.getColumn(11).width = 10
            worksheet.getColumn(12).width = 9
            worksheet.getColumn(13).width = 9
            for (let i = 18; i <= 48; i++) {
                worksheet.getColumn(i).width = 3;
            }
        }


        dataLocation.map(data => {
            // хэдер
            const cellQ4 = data.sheet.getCell("Q4");
            const cellQ5 = data.sheet.getCell("Q5");
            const cellQ6 = data.sheet.getCell("Q6");
            const cellA1 = data.sheet.getCell("A1");

            data.sheet.getColumn('N').width = 0;
            data.sheet.getColumn('O').width = 0;
            data.sheet.getColumn('P').width = 0;
            cellA1.value = moment(dataGraph.data[0].workDate).format('DD.MM.YYYY');
            cellQ4.value = `Хантос, ${data.sheet.name}`;
            cellQ4.font = {name: "Arial", bold: true, size: 12};
            cellQ5.value = "Г   Р   А   Ф   И   К";
            cellQ5.font = {name: "Arial", bold: true, size: 12};
            cellQ6.value = `Периодической очистки скважин от АСПО на ${moment(dataGraph.data[0].workDate).locale('ru').format('MMMM')} ${moment(dataGraph.data[0].workDate).format('YYYY')}.`;

            cellQ6.font = {name: "Arial", bold: true, size: 12};

            // legend
            const cellAV1 = data.sheet.getCell("AW2");
            const cellAV2 = data.sheet.getCell("AW3");
            const cellAV3 = data.sheet.getCell("AW4");
            const cellAV4 = data.sheet.getCell("AW5");
            const cellAV5 = data.sheet.getCell("AW6");

            cellAV1.value = 'Легкая'
            cellAV2.value = 'Средняя'
            cellAV3.value = 'Тяжёлая'
            cellAV4.value = 'Непроход'
            cellAV5.value = 'Орг. причины'

            data.sheet.getCell("AV2").fill =
                {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: '80BF20'}
                };
            data.sheet.getCell("AV3").fill =
                {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'FFDF60'}
                };
            data.sheet.getCell("AV4").fill =
                {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: '9F2000'}
                };
            data.sheet.getCell("AV5").fill =
                {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'DF20DF'}
                };
            data.sheet.getCell("AV6").fill =
                {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'C0C0C0'}
                };


            // шапка таблицы
            const row = data.sheet.getRow(9);
            row.font = {name: "Arial Cyr", bold: true, size: 10};
            row.height = 32;
            columnArr.forEach((value, index) => {
                const cell = row.getCell(index + 1);
                // @ts-ignore
                cell.fill = style.fill;
                // @ts-ignore
                cell.border = style.border;
                cell.value = value;
                cell.alignment = {
                    horizontal: 'center', vertical: 'middle', wrapText: true,
                };
            });
            setColumnWidth(data.sheet)
            data.data.map((e: any, index: any) => {
                let interval1 = undefined
                let interval2 = undefined
                if (e['interval']) {
                    const interval = e['interval'].split('-')
                    interval1 = interval[0]
                    interval2 = interval[1]
                }

                if (e['bsi_level'] === 'Тяжелая') {
                    e['bsi_level'] = 'Тяжёлая'
                }
                if (e['level'] === 'Тяжелая') {
                    e['level'] = 'Тяжёлая'
                }
                const rowData = [e['№'], e['bush'], e['dns'], e['deposit'], e['org'],
                    e['wells'].name, e['depth'], interval1, interval2, e['level'], e['work_mode'], e['work_time'], e['nakop_time'],
                    e['era_id_mer'], e['era_id_isp'], e['era_id_mer_isp'], e['diam'], e['d1'].plan, e['d2'].plan,
                    e['d3'].plan, e['d4'].plan, e['d5'].plan, e['d6'].plan, e['d7'].plan, e['d8'].plan, e['d9'].plan,
                    e['d10'].plan, e['d11'].plan, e['d12'].plan, e['d13'].plan, e['d14'].plan, e['d15'].plan, e['d16'].plan,
                    e['d17'].plan, e['d18'].plan, e['d19'].plan, e['d20'].plan, e['d21'].plan, e['d22'].plan, e['d23'].plan,
                    e['d24'].plan, e['d25'].plan, e['d26'].plan, e['d27'].plan, e['d28'].plan, e['d29']?.plan, e['d30']?.plan,
                    e['d31']?.plan, e['total_plan'], e['total_fact'], e['comment'], '', '', e['bsi_level']]
                data.sheet.getRow(index + 10).values = rowData;

                // границы ячеек
                for (let i = 1; i <= rowData.length; i++) {
                    // @ts-ignore
                    data.sheet.getCell(index + 10, i).border = style.border
                }

                // отметки на графике о результатах прохода
                checkComment(data.sheet, e, index);
            })
        });

        const writeFile = (fileName: any, content: any) => {
            const link = document.createElement("a");
            const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });

            // Добавляем эту строку для поддержки заметок
            const url = URL.createObjectURL(blob);

            link.download = fileName;
            link.href = url;

            link.click();

            // Очищаем URL после скачивания файла
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 100);
        };
        workbook.xlsx.writeBuffer().then(buffer => {
            writeFile('export_graph.xlsx', buffer);
        });
    };
    exportToExcel(dataGraph)
};

export default ExportToExcel;
