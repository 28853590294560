import Workbook from "exceljs/index";
import CliffReportPage from "./cliffReportPage";
import PpkReportPage from "./ppkReportPage";
import RepairsReportPage from "./repairsReportPage";
import ExcelJs from "exceljs";

// Функция для безопасного объединения ячеек
function safeMergeCells(worksheet: any, mergeRange: string) {
    // Проверка, не объединена ли уже ячейка (альтернативный способ)
    const merges = worksheet._merges;
    if (!merges[mergeRange]) {
        worksheet.mergeCells(mergeRange);
    }
}

// Функция для мапинга списков всяких орг причин
function createSection(rowNum: number, style: any, worksheet: any, title: string, headers: string[], rowData: any[], startColumn: string, endColumn: string) {
    rowNum += 2;
    safeMergeCells(worksheet, `${startColumn}${rowNum}:${endColumn}${rowNum}`);
    const headerCell = worksheet.getCell(`${startColumn}${rowNum}`);
    headerCell.value = title;
    headerCell.alignment = {vertical: 'middle', horizontal: 'left', wrapText: false};

    rowNum += 1;
    const headerRow = worksheet.getRow(rowNum);
    headers.forEach((header, i) => {
        const cell = headerRow.getCell(i + 2); // Стартуем с ячейки B (вторая ячейка)
        cell.value = header;
        cell.alignment = {vertical: 'middle', horizontal: 'left', wrapText: false};
        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '66ccff'}};
        cell.border = style.border;
    });

    rowData.forEach((e: any) => {
        rowNum += 1;
        const row = worksheet.getRow(rowNum);

        // Вставляем значения начиная со второй колонки вручную
        row.getCell(2).value = e.company;
        row.getCell(3).value = e.day;
        row.getCell(4).value = e.location;
        row.getCell(5).value = e.deposit;
        row.getCell(6).value = e.bush;
        row.getCell(7).value = e.wells;
        if (e.depth) {
            row.getCell(8).value = e.depth;
            row.getCell(9).value = e.input_type;
        }
        if (e.comment) {
            row.getCell(8).value = e.comment;
        }

        // Добавляем границы и цвет начиная со второй колонки
        for (let i = 2; i <= 10; i++) {
            const cell = row.getCell(i);

            // Проверяем, есть ли значение в ячейке
            if (cell.value) {
                cell.border = style.border; // Добавляем границы только если ячейка не пустая
            }
        }

    });

    return rowNum
}

const MainPageReport = (repData: any, setLoading: any, date: any) => {
    const workbook = new ExcelJs.Workbook();

    const cliffList = repData.data['cliff_list']
    const ppkList = repData.data['ppk_list']
    const repairsList = repData.data['repairs_list']

    delete repData.data['cliff_list']
    delete repData.data['ppk_list']
    delete repData.data['repairs_list']


    for (const key in repData.data) {

        // создаем листы в книге
        const worksheet = workbook.addWorksheet(key, {
            views: [{showGridLines: true}]
        });

        worksheet.getCell("A1").value = date;

        // делаем шапку //
        // имена в шапке и ширина столбцов
        worksheet.mergeCells("B2:B4");
        worksheet.getCell("B2").value = "Подрядная организация";
        worksheet.getColumn(2).width = 25

        worksheet.mergeCells("C2:C4");
        worksheet.getCell("C2").value = "Общее количество";
        worksheet.getColumn(3).width = 12

        worksheet.mergeCells("D2:E3");
        worksheet.getCell("D2").value = "Кол-во экипажей";
        worksheet.getCell("D4").value = "план";
        worksheet.getCell("E4").value = "факт";
        worksheet.getColumn(4).width = 8
        worksheet.getColumn(5).width = 8

        worksheet.mergeCells("F2:F4");
        worksheet.getCell("F4").value = "Неисправные СКК";
        worksheet.getColumn(6).width = 12

        worksheet.mergeCells("G2:G4");
        worksheet.getCell("G4").value = "комментарий";
        worksheet.getColumn(7).width = 8

        worksheet.mergeCells("H2:S2");
        worksheet.getCell("H2").value = "Фонд";
        worksheet.getCell("H3").value = "график";
        worksheet.getCell("H4").value = "СКВ.";
        worksheet.mergeCells("I3:M3");
        worksheet.getCell("I3").value = "охваченный";
        worksheet.getCell("I4").value = "с 1 обработкой";
        worksheet.getCell("J4").value = "с 2 обработками";
        worksheet.getCell("K4").value = "с 3 обработками";
        worksheet.getCell("L4").value = "более 3х обработок";
        worksheet.getCell("M4").value = "ИТОГО";
        worksheet.mergeCells("N3:S3");
        worksheet.getCell("N3").value = "не охваченный";
        worksheet.getCell("N4").value = "без подхода";
        worksheet.getCell("O4").value = "непроход по НКТ";
        worksheet.getCell("P4").value = "остановлены";
        worksheet.getCell("Q4").value = "неисправна Б/З";
        worksheet.getCell("R4").value = "нет подъезда";
        worksheet.getCell("S4").value = "прочие причины";
        worksheet.getCell("T4").value = "ИТОГО";
        worksheet.getColumn(8).width = 8
        worksheet.getColumn(9).width = 8
        worksheet.getColumn(10).width = 8
        worksheet.getColumn(11).width = 8
        worksheet.getColumn(12).width = 8
        worksheet.getColumn(13).width = 8
        worksheet.getColumn(14).width = 8
        worksheet.getColumn(15).width = 8
        worksheet.getColumn(16).width = 8
        worksheet.getColumn(17).width = 8
        worksheet.getColumn(18).width = 8
        worksheet.getColumn(19).width = 8
        worksheet.getColumn(20).width = 8

        worksheet.mergeCells("U2:U3");
        worksheet.getCell("U2").value = "Кол-во ликвид (в.тек.мес)";
        worksheet.getCell("U4").value = "СКВ.";
        worksheet.getColumn(21).width = 10

        worksheet.mergeCells("V2:X3");
        worksheet.getCell("V2").value = "Кол-во обработок в тек.месяце";
        worksheet.getCell("V4").value = "план";
        worksheet.getCell("W4").value = "факт";
        worksheet.getCell("X4").value = "отстав.";
        worksheet.getColumn(22).width = 7
        worksheet.getColumn(23).width = 7
        worksheet.getColumn(24).width = 7

        worksheet.mergeCells("Y2:Y4");
        worksheet.getCell("Y2").value = "Количество загруженных БСИ по ПО";
        worksheet.getColumn(25).width = 21

        worksheet.mergeCells("Z2:AB3");
        worksheet.getCell("Z2").value = "Категории осложнений (кол-во)";
        worksheet.getCell("Z4").value = "Легкая";
        worksheet.getCell("AA4").value = "Средняя";
        worksheet.getCell("AB4").value = "Сложная";
        worksheet.getColumn(26).width = 10
        worksheet.getColumn(27).width = 10
        worksheet.getColumn(28).width = 10

        // Задаем стиль для ячеек шапки
        const style = {
            border: {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            }
        };

        // перебераем шапку таблицы
        for (let i = 2; i <= 4; i++) {
            const row = worksheet.getRow(i);
            for (let i = 2; i <= 28; i++) {
                const cell = row.getCell(i);
                // @ts-ignore
                cell.border = style.border;
                cell.font = {bold: true}
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: '66ccff'},
                };
            }
        }

        // выравниваем по центру и по середине, перенос строк
        for (let col = 2; col <= 29; col++) {
            const column = worksheet.getColumn(col);
            column.alignment = {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            };
        }

        // инсертим данные
        let rowNum = 0
        repData.data[key].map((e: any, index: number) => {
            rowNum = index + 5
            const row = worksheet.getRow(rowNum)
            row.height = 50
            row.values = ['', e['company'], e['total_plan'], e['total_equipage'], e['equipage_fact'],
                e['equipage_repairs'], '', e['total_plan'], e['total_plan_work_1'], e['total_plan_work_2'],
                e['total_plan_work_3'], e['total_plan_work_more'], e['total_plan_work'], e['total_plan_unwork'], e['no_passage'],
                e['org_reason_stopped'], e['org_reason_bz'], e['org_reason_noway'], e['by_org_reason_other'],
                e['total_plan_unwork'] + e['no_passage'] + e['org_reason_stopped'] + e['org_reason_bz'] + e['org_reason_noway'] + e['by_org_reason_other'],
                e['liquidation'], e['todate_passage_plan'], e['todate_passage_fact'],
                e['todate_passage_plan'] - e['todate_passage_fact'], e['bsi_count'], e['easy_passage'],
                e['medium_passage'], e['hard_passage']];

            // Добавляем границы вокруг ячеек
            for (let i = 2; i <= 28; i++) {
                const cell = row.getCell(i)
                // @ts-ignore
                cell.border = style.border;
            }
            // Окрашиваем ячейки от I до M в светло-зеленый
            for (let i = 9; i <= 13; i++) {
                const cell = row.getCell(i);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'ceefce'} // светло-зеленый
                };
            }

            // Окрашиваем ячейки от N до T в светло-красный
            for (let i = 14; i <= 20; i++) {
                const cell = row.getCell(i);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'f8ebea'} // светло-красный
                };
            }
        })

        rowNum += 2

        // выводим списки
        const headersTemplate = ['Подрядчик', 'Дата выявления', 'ЦОД', 'М/Р', 'Куст', 'Скважина', 'Глубина', 'Данные'];
        const otherHeadersTemplate = ['Подрядчик', 'Дата выявления', 'ЦОД', 'М/Р', 'Куст', 'Скважина', 'Причина'];


        // Непроходы
        const noPassageData = repData.data[key].filter((e: any) => e.no_passage > 0);
        noPassageData.forEach((e: any) => {
            const noPassageList = e.no_passage_list.map((el: any) => ({
                company: e.company,
                day: el.day,
                location: el.location,
                deposit: el.deposit,
                bush: el.bush,
                wells: el.wells,
                depth: el.bsi ? el.bsi[el.bsi.length - 1].depth : el.depth,
                input_type: el.input_type,
            }));
            rowNum = createSection(rowNum, style, worksheet, 'Непроходы:', headersTemplate, noPassageList, 'B', 'I');
        });

        // Ликвидации
        const liquidationData = repData.data[key].filter((e: any) => e.liquidation > 0);
        liquidationData.forEach((e: any) => {
            const liquidationList = e.liquidation_data.map((el: any) => ({
                company: e.company,
                day: el.day,
                location: el.location,
                deposit: el.deposit,
                bush: el.bush,
                wells: el.wells,
                depth: el.bsi ? el.bsi[el.bsi.length - 1].depth : el.depth,
                input_type: el.input_type,
            }));
            rowNum = createSection(rowNum, style, worksheet, 'ЛГПП:', headersTemplate, liquidationList, 'B', 'I');
        });

        // Остановленные скважины
        const stoppedData = repData.data[key].filter((e: any) => e.org_reason_stopped > 0);
        stoppedData.forEach((e: any) => {
            const stoppedList = e.org_reason_stopped_list.map((el: any) => ({
                company: e.company,
                day: el.day,
                location: el.location,
                deposit: el.deposit,
                bush: el.bush,
                wells: el.wells,
            }));
            rowNum = createSection(rowNum, style, worksheet, 'Скважины остановлены:', headersTemplate.slice(0, 6), stoppedList, 'B', 'G');
        });

        // Неисправна Б/З
        const bzData = repData.data[key].filter((e: any) => e.org_reason_bz > 0);
        bzData.forEach((e: any) => {
            const bzList = e.org_reason_BZ_list.map((el: any) => ({
                company: e.company,
                day: el.day,
                location: el.location,
                deposit: el.deposit,
                bush: el.bush,
                wells: el.wells,
            }));
            rowNum = createSection(rowNum, style, worksheet, 'Неисправна Б/З:', headersTemplate.slice(0, 6), bzList, 'B', 'G');
        });

        // Нет подъезда
        const noWayData = repData.data[key].filter((e: any) => e.org_reason_noway > 0);
        noWayData.forEach((e: any) => {
            const noWayList = e.org_reason_noway_list.map((el: any) => ({
                company: e.company,
                day: el.day,
                location: el.location,
                deposit: el.deposit,
                bush: el.bush,
                wells: el.wells,
            }));
            rowNum = createSection(rowNum, style, worksheet, 'Нет подъезда:', headersTemplate.slice(0, 6), noWayList, 'B', 'G');
        });

        // Нет подъезда
        const otherData = repData.data[key].filter((e: any) => e.by_org_reason_other > 0);
        otherData.forEach((e: any) => {
            const otherList = e.by_org_reason_other_list.map((el: any) => ({
                company: e.company,
                day: el.day,
                location: el.location,
                deposit: el.deposit,
                bush: el.bush,
                wells: el.wells,
                comment: el.comment.label,
            }));
            rowNum = createSection(rowNum, style, worksheet, 'Прочие орг. причины:', otherHeadersTemplate, otherList, 'B', 'G');
        });
        setLoading(false)
    }

    // Обрывы
    CliffReportPage(cliffList, workbook)

    // ППК
    PpkReportPage(ppkList, workbook)

    // Ремонты
    RepairsReportPage(repairsList, workbook)

    const writeFile = (fileName: any, content: any) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        // Добавляем эту строку для поддержки заметок
        const url = URL.createObjectURL(blob);

        link.download = fileName;
        link.href = url;

        link.click();

        // Очищаем URL после скачивания файла
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 100);
    };
    workbook.xlsx.writeBuffer().then((buffer: any) => {
        writeFile(`report_${date.replace('.', '')}.xlsx`, buffer);
    });
}

export default MainPageReport